import React from 'react';

import Heading from './Heading';
import { Container, Row, Col } from 'react-grid-system';
import { StyledTestimonials, StyledTestimonialsThumb } from '../styles/StyledTestimonials';

import clientThumb from '../images/freshlylocal-logo.png';

const Testimonials = ({ header }) => (
  <StyledTestimonials>
    <Container>
      <Row>
        <Col>
          <Heading text={header} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <StyledTestimonialsThumb src={clientThumb} alt="Freshly Local" />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <p className="testimonial-text">Annie played a key role in the set up of our business, she always stuck to deadlines, came up with some amazing ideas and made our branding look awesome!! These are just some of the reasons we continue to use her!</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <p className="testimonial-client">Laura McMillan, Director Freshly Local</p>
        </Col>
      </Row>
    </Container>
  </StyledTestimonials>
);

export default Testimonials;
