import styled from 'styled-components';

export const StyledSingleProject = styled.div`
         margin: 40px 0;
         h2 {
           margin: 40px 0;
         }
         h3 {
           margin-bottom: 10px;
         }
         img {
           width: 100%;
           height: 100%;
           object-fit: contain;
         }
         .showcase-title {
           font-size: 1.2rem;
           text-align: center;
           margin-top:-20px;
           margin-bottom:20px;
           text-transform: uppercase;
         }
       `;

export const StyledSingleProjectInfoBox = styled.div`
    margin-top: 40px;
    margin-bottom:50px;

    div {
        font-size: 1.2rem;
        line-height: 1.3rem;
    }
`;