const projects = {
  dunleyUpdate: {
    id: "14",
    name: "Dunley Farm - 2021 update",
    image: "images/dunley-2021.jpg",
    image_showcase: [
      {
        image_id: "01",
        image_src: "images/dunley-2021/home.jpg",
        image_title: "Home"
      },
      {
        image_id: "02",
        image_src: "images/dunley-2021/testimonial.jpg",
        image_title: "Testimonial"
      },
      {
        image_id: "03",
        image_src: "images/dunley-2021/rooms.jpg",
        image_title: "Rooms"
      }
    ],
    desc: "Website Design / Website Development",
    client: "Geoff Drake",
    finished_project:
      "Website refresh and build",
    date: "April 2021",
    brief:
      "<p>The client owns a holiday home in Devon and after a successful few years, they wanted to update the website we created in 2015 together. To help with bookings, the client wanted to automate visitors being able to check availability and book online. They also found having to manually add testimonials to the site time consuming.</p>",
    project:
      "<p>To update the website, the branding and colours were kept the same but I updated the layout to modernise the site. I integrated the Tripadvisor review app so that reviews will pull automatically from Tripadvisor to save the client having to manually add them. It also gives credibility to the reviews and offers reassurance to visitors that the reviews are genuine. I added the booking form to the top of the homepage to help users quickly and easy check availability.</p>",
    url: "https://www.dunleyfarm.co.uk/",
  }, 
  stPeters: {
    id: "12",
    name: "St. Peter’s Pre-School Ardingly",
    image: "images/st-peters-mockup.jpg",
    image_showcase: [
      {
        image_id: "01",
        image_src: "images/st-peters/old-home.png",
        image_title: "Old Home"
      },
      {
        image_id: "02",
        image_src: "images/st-peters/old-about.png",
        image_title: "Old About"
      },
      {
        image_id: "03",
        image_src: "images/st-peters/old-photo.png",
        image_title: "Old Photo gallery"
      },
      {
        image_id: "04",
        image_src: "images/st-peters/new-home.jpg",
        image_title: "New Home"
      },
      {
        image_id: "05",
        image_src: "images/st-peters/new-about.jpg",
        image_title: "New About"
      },
      {
        image_id: "06",
        image_src: "images/st-peters/new-gallery.jpg",
        image_title: "New Photo gallery"
      }
    ],
    desc: "Website Design / Website Development / Hosting",
    client: "Mary Curran",
    finished_project:
      "Website refresh and build",
    date: "March 2021",
    brief:
      "<p>The client was a local pre-school that needed to update their existing website to make it easier to navigate, showcase their offering and attract families to sign up their children.</p>",
    project:
      "<p>A major pain point of the existing website for the client was that they couldn't update the website themselves. They wanted to be able to easily add new images and update content as required. With this in mind, I created a custom themed Wordpress website. I simplified the navigation and reduced the amount of content on the homepage to make it easier for visitors to get a quick overview of the preschool.</p><p>I created a new gallery that enabled the client to easily add new images and arrange them in an appealing way.</p>",
    url: "https://st-peters-preschool-ardingly.org/",
    testimonial: "<p>&ldquo;Annie was a pleasure to work with and worked tirelessly to create a new website for our charity within a set timeframe. Annie really understood what we wanted to achieve and gave us excellent guidance along the way. It made for a very easy and collaborative process. </p><p>We really appreciate that Annie was always available to answer our queries and to make 'just one more tweek!'. We are thrilled with our website. I recommend her unreservedly for her hard work, creativity, customer care and patience in abundance.&rdquo;</p><p><cite>M Curran</cite></p>"
  }, 
  YogawithYlva: {
    id: "13",
    name: "Yoga with Ylva",
    image: "images/yoga-with-ylva.jpg",
    image_showcase: [
      {
        image_id: "01",
        image_src: "images/yoga-with-ylva/old-home.png",
        image_title: "Old Home"
      },
      {
        image_id: "02",
        image_src: "images/yoga-with-ylva/old-class.png",
        image_title: "Old Class info"
      },
      {
        image_id: "03",
        image_src: "images/yoga-with-ylva/old-equipment.png",
        image_title: "Old equipment page"
      },
      {
        image_id: "04",
        image_src: "images/yoga-with-ylva/new-home.png",
        image_title: "New Home"
      },
      {
        image_id: "05",
        image_src: "images/yoga-with-ylva/new-class.png",
        image_title: "New Class info"
      },
      {
        image_id: "06",
        image_src: "images/yoga-with-ylva/new-equipment.jpg",
        image_title: "New equipment page"
      }
    ],
    desc: "Website Design / Website Development / Hosting",
    client: "Ylva",
    finished_project:
      "Website refresh and build",
    date: "November 2020",
    brief:
      "<p>The client is a yoga teacher and runs yoga classes locally. They wanted to refresh their existing site to convey a modern, clean and calm site. It was important that the information was easy to understand for users and that the theme of 'movement' was incorporated throughout.</p>",
    project:
      "<p>As the client's existing site was built with Wordpress, we agreed that the new site would be a new custom Wordpress theme, this would allow the client to easily update content when required. To refresh the site, I reduced the amount of information on the homepage dramatically and added a lot more imagery. Where possible, I tried to find creative ways of displaying information, like the new classes block, so that users could see at a glance the type of class that would be suitable. The whole site uses subtle animations to introduce the content and the colour scheme uses a muted colour palette to convey the sense of calm and movement.</p>",
    url: "https://yogawithylva.com/",
    testimonial: "<p>&ldquo;Annie recently redid and rebranded my whole website and brand. I honestly couldn't be happier with the finished product. The whole process was really smooth and uncomplicated. Anything I asked for was possible or could be explained. Despite probably constantly using the wrong terminology, Annie helped come up with ideas and designs until I felt it was exactly what I wanted.</p><p>Thank you so much Annie!&rdquo;</p><p><cite>Ylva</cite></p>"
  }, 
  emilyking: {
    id: "11",
    name: "Emily King Counselling",
    image: "images/emily-king.jpg",
    image_showcase: [
      {
        image_id: "11",
        image_src: "images/emily-king/contact.jpg",
        image_title: "Contact form"
      },
      {
        image_id: "02",
        image_src: "images/emily-king/location.jpg",
        image_title: "Location information and map"
      },
      {
        image_id: "03",
        image_src: "images/emily-king/services.jpg",
        image_title: "Overview of services available"
      }
    ],
    desc: "Brand development / Website Design / Website Development",
    client: "Emily King",
    finished_project:
      "Design and build website",
    date: "June 2020",
    brief:
      "<p>The client needed to create an online presence for their new counselling business to let potential clients know about the counselling services on offer. The website needed to give potential clients an overview of the client's professional qualifications and highlight each of the services available.</p>",
    project:
      "<p>The client needed a site to clearly explain to future clients how she could help them and information about the business. It needed to be easy to navigate, quick to load and clearly display information about location, services on offer and prices. The site uses calm, professional colours to reflect the client's approach to counselling work.</p><p>Since the client does not need to regularly update content on the site, she did not need a CMS and so the site is built using React.</p>",
    url: "https://emilykingcounselling.co.uk/",
    testimonial: "<p>&ldquo;Annie was an absolute dream to work with, nothing was ever too much trouble. At first I was reluctant to have a website for my new business as I didn't want to deal with the hassle of creating one, however I found the whole process hassle free and Annie handled everything. I've ended up with an amazing website that has helped me gain many new clients.</p><p>Annie was so helpful and helped me create a business domain and sorted my hosting - none of which I knew I'd need! We had several calls where she listened to what I needed and then made some brilliant suggestions!</p><p>Many of my clients have commented on how much they love my website and I'm proud of the first impression it gives of my business. I would highly recommend Annie, she is incredibly professional and has such a friendly manner that you instantly feel at ease in what could be a daunting situation!&rdquo;</p><p><cite>Emily King</cite></p>"
  }, 
  mexicantina: {
    id: "0",
    name: "Mexicantina",
    image: "images/mexicantina.jpg",
    image_showcase: [
      {
        image_id: "11",
        image_src: "images/mexicantina/menu.jpg",
        image_title: "The menu"
      },
      {
        image_id: "02",
        image_src: "images/mexicantina/add-to-order.jpg",
        image_title: "Customise your dish and add extras"
      },
      {
        image_id: "03",
        image_src: "images/mexicantina/checkout.jpg",
        image_title: "Select a delivery slot"
      }
    ],
    desc: "Brand development / Website Design / Website Development",
    client: "Flinder's Coffee",
    finished_project:
      "Design and build website",
    date: "May 2020",
    brief:
      "<p>The client needed to expand their food and beverage business to cope with the changing landscape created by Covid-19. They decided to expand into doing Mexican takeway deliveries in the evenings and so needed a website that would introduce the new business, showcase the menu and allow users to place their order, and they needed it built quickly!</p><p>The website needed to allow users to select a specific time slot and for the number of these to be restricted to allow the new business to manage orders and ensure hot and delicious food is delivered.</p>",
    project:
      "<p>To get the website up and running as quickly as possible, I opted to build a Wordpress site as I knew that I could rely on the plugins to handle the ecommerce nature of the site.</p><p>The site is a custom theme and the tone and branding was created around the logo the client supplied. We wanted to create a sophisticated and fresh offering to the residents of Haywards Heath.</p><p>Customers can view images and details of the dishes available, add them to their basket and then select a delivery slot at the checkout. If there are no additional slots available for the day, the site will let the user know and they can't process with their order</p><p>Once the site was built, I created a guide to help my client maintain and update their site, change product prices, add new dishes and amend the delivery slots available. We went through the guide together and I have offered ongoing support since.</p>",
    url: "https://mexicantina.co.uk/"
  }, 
  rewardsBag: {
    id: "1",
    name: "Rewards Bag",
    image: "images/rewards-bag.jpg",
    image_showcase: [],
    desc: "Web development / SVG animation / CSS animation",
    client: "Amplify Media Partners Ltd",
    finished_project:
      "Update Wordpress website to new design, implement animations, SEO best practice",
    date: "July 2019",
    brief:
      "<p>The client had a new website design and needed to update their current website to reflect the new style.</p><p>The client wanted the CMS to remain unchanged to ensure they could continue to update and create new content.</p><p>The new site will be accessed by English and German speaking users, and on a range of devices so it needs to be both multi-language and responsive.</p>",
    project:
      "<p>I maintained the structure of the client's current Wordpress website but created new custom modules for each new section required. Allowing the client to continue maintaining their site as they had been, with minimal training required for the new sections.</p><p>To create animation, I used a combination of CSS3 animations, Javascript and SVG animations. I adopted a different approach for each animation depending on the interaction, play time and its requirements.</p><p>The site is fully responsive and users can switch between German and English when using the site.</p>",
    url: "https://rewardsbag.com/"
  },
  eperformance: {
    id: "2",
    name: "ePerformance",
    image: "images/eperformance.jpg",
    image_showcase: [
      {
        image_id: "21",
        image_src: "images/eperformance/what-we-do.jpg",
        image_title: "What we do"
      },
      {
        image_id: "22",
        image_src: "images/eperformance/testimonials.jpg",
        image_title: "Testimonials"
      },
      {
        image_id: "23",
        image_src: "images/eperformance/client-showcase.jpg",
        image_title: "Client showcase"
      }
    ],
    desc: "Website design / Web development / Branding",
    client: "Amplify Media Partners Ltd",
    finished_project: "Design and build website",
    date: "May 2019",
    brief:
      "<p>Amplify Media Partners Ltd. needed a website to showcase their new venture. They had a logo and a rough idea of colour schemes, the rest was up to me.</p><p>The website needed to be easy to maintain and update and it needed to be responsive.</p>",
    project:
      "<p>I designed a single scroll website that separated content using bold colours and imagery. The use of the colour blue was one of the key requirements and so this is a primary colour used throughout.</p><p>I built the website in Wordpress with a custom theme to enable the client to easily update and maintain the site.</p>",
    url: "http://eperformance.io/"
  },
  redk: {
    id: "3",
    name: "RedK",
    image: "images/redk.jpg",
    image_showcase: [
      {
        image_id: "24",
        image_src: "images/redk/redk-header.jpg",
        image_title: "Landing Page - Header"
      },
      {
        image_id: "25",
        image_src: "images/redk/whitepaper.jpg",
        image_title: "White Paper Section"
      },
      {
        image_id: "26",
        image_src: "images/redk/banner-ads.jpg",
        image_title: "Banner Ad Sizes"
      }
    ],
    desc:
      "Display advertising / Landing page design / Landing page development",
    client: "RedK",
    finished_project: "Design banner ads, design and build landing page",
    date: "June 2018",
    brief:
      "<p>RedK needed to increase their eBook downloads. To do this they wanted a series of banner ads to direct users to a landing page where in exchange for their contact details, a user would gain access to the eBook.</p><p>The landing page needs to inegrate seemlessly into the current site and provide measurable results.</p>",
    project:
      "<p>I created a series of banner ads in multiple sizes that could be used in various advertising spots. Each banner was optimised for the size required with a clear call-to-action to drive users to the landing page.</p><p>The landing page was created within RedK's existing site.</p>",
    url: "https://www.redk.net/en-GB/"
  },
  wifefest: {
    id: "4",
    name: "Wife-Fest",
    image: "images/wife-fest-mockup.jpg",
    image_showcase: [
      {
        image_id: "27",
        image_src: "images/wife-fest/meet-the-team-lg.jpg",
        image_title: "Meet our Bridal Party"
      },
      {
        image_id: "28",
        image_src: "images/wife-fest/info-lg.jpg",
        image_title: "Key information"
      },
      {
        image_id: "29",
        image_src: "images/wife-fest/rsvp-lg.jpg",
        image_title: "RSVP"
      },
      {
        image_id: "30",
        image_src: "images/wife-fest/wife-fest-invites.jpg",
        image_title: "Printed Wedding invitations"
      },
      {
        image_id: "31",
        image_src: "images/wife-fest/smore-sticker.jpg",
        image_title: "Personalised Stickers for S'Mores"
      },
      {
        image_id: "31",
        image_src: "images/wife-fest/tattoo.jpg",
        image_title: "Temporary Tattoo design"
      }
    ],
    desc: "Branding / Web design / Web development",
    client: "My wife",
    finished_project:
      "Wedding website, Invitation, Personalised stickers and Temporary tattoos",
    date: "June 2017",
    brief:
      "<p>Possibly the hardest brief I have ever had to meet as the customer in this project was myself and my wife. We wanted a website to display all the information we needed our guests to know ahead of our big day. It needed to be easy to use (for our older guests) but still fun, informative and a reflection of the wedding we were planning.</p><p>To save costs we decided to email our Save the Dates to our guests.</p><p>We also needed an invitation that would tie in with the website and direct our guests to RSVP online.</p><p>Everything created digitally had to be responsive as we knew that a large number of our guests would be viewing the information on their phones.</p>",
    project:
      "<p>I created a single page website that had a fun introduction to our wedding party, information about the venue, gifts, a countdown timer and the all important RSVP form.</p><p>To inject a touch of fun, and convey that the wedding was not going to be normal traditional 'do', I added quirky illustrations inspired by our venue and theme.</p><p>I carried the theme through to the invite to create continuity. Elements from the website and invite even cropped up on our big day as I used the same illustrations and fonts for our personalised stickers and temporary tattoos!</p>",
    url: "http://32pixels.co.uk/mazandpea/"
  },
  freshlylocal: {
    id: "7",
    name: "Freshly Local",
    image: "images/freshly-local-mockup.jpg",
    image_showcase: [
      {
        image_id: "35",
        image_src: "images/freshly-local/recipe-cards.jpg",
        image_title: "Recipe Cards"
      },
      {
        image_id: "36",
        image_src: "images/freshly-local/welcome-email.jpg",
        image_title: "Welcome Email"
      },
      {
        image_id: "37",
        image_src: "images/freshly-local/raffle.jpg",
        image_title: "Raffle Prize Flyer"
      }
    ],
    desc: "Email Design / Email Development / Marketing materials",
    client: "Freshly Local",
    finished_project:
      "Creation of welcome email series, printed recipe cards and printed leaflet",
    date: "June 2017",
    brief:
      "<p>Freshly Local approached me just before launching their new business with a shiny set of brand guidelines and lots of ideas. As a new local food box delivery service, they needed marketing material that would reflect their passion for great tasting, locally sourced food.</p><p>The team at Fresly Local wanted to include a recipe card for each meal their customers would receive in the food box. They needed the card to be easy to read and recognisably Freshly Local.</p><p>As a new venture, the team also needed a way to welcome new email subscribers to the Freshly Local ethos.</p>",
    project:
      "<p>I created Freshly Local an eye-catching template for the recipe cards with a focus on easy to read instructions and large images of the enticing meals. Freshly Local's distinctive branding is carried throughout the cards and is easily adaptable to suit the varying lengths of recipes the team needed.</p><p>To help the team keep the recipes up to date, I also provided a training session for the team to show them how to use the software and a written guide to keep for reference.</p><p>To give new subscribers a warm welcome to the Freshly Local ethos, I created a series of emails to welcome them. Each email focuses on a different area of the business and ensures the content is short, concise and engaging.</p>",
    url: ""
  },
  withloveco: {
    id: "5",
    name: "With Love & Co.",
    image: "images/with-love-and-co-mockup.jpg",
    image_showcase: [
      {
        image_id: "32",
        image_src: "images/with-love-and-co/home-lg.jpg",
        image_title: "Homepage"
      },
      {
        image_id: "33",
        image_src: "images/with-love-and-co/products-lg.jpg",
        image_title: "Product showcase"
      },
      {
        image_id: "34",
        image_src: "images/with-love-and-co/product-page-lg.jpg",
        image_title: "Product page Example"
      }
    ],
    desc: "Branding / Web design / Web development",
    client: "With Love & Co.",
    finished_project: "Branding and Website",
    date: "June 2016",
    brief:
      "<p>As a start-up, With Love & Co. just had a presence on Social Media when they approached me. They needed a website to showcase their products and help to translate their current branding into digital.</p><p>The company provide a full service events solution, from invitation designs, to entertainment and decor hire. As a highly dedicated due, the two directors needed a modern and stylish website that would allow them to easily add new products and easily reply to customer enquiries.</p>",
    project:
      "<p>Since the requirement to be able to easily add and remove products depending on stock, I created With Love & Co. a bespoke Wordpress website. Using Wordpress meant that the pair could easily update the site using the easy-to-use CMS, whilst still giving me the freedom to create them a bespoke website that reflected their brand voice.</p><p>The final product is a slick and modern looking website, with clear stock pages and an easy to use contact form.</p>",
    url: "http://withloveandco.com.au/"
  },
  sussexdivingclub: {
    id: "6",
    name: "Sussex Diving Club",
    image: "images/sussex-dive-mockup.jpg",
    image_showcase: [],
    desc: "Graphic Design",
    client: "Sussex Diving Club",
    finished_project: "Poster to promote local diving club",
    date: "June 2016",
    brief:
      "<p>Sussex Diving Club needed a poster to attract new members and advertise the 'try dives' that they were holding on two dates. The poster needed to be eye-catching and showcase the diving possibilities right here in the UK.</p>",
    project:
      "<p>I created a colourful poster that utilised actual photos taken by club members to highlight the wildlife spotted in British waters. As the club wanted to send the poster to members to print off, it is a high quality A4 pdf, able to be printed in a home or office printer.</p>",
    url: ""
  },
  dunleyfarmhouse: {
    id: "8",
    name: "Dunley Farmhouse",
    image: "images/dunley-farm-mockup.jpg",
    image_showcase: [
      {
        image_id: "38",
        image_src: "images/dunley-farm/home.jpg",
        image_title: "Homepage"
      },
      {
        image_id: "39",
        image_src: "images/dunley-farm/about.jpg",
        image_title: "About us"
      },
      {
        image_id: "40",
        image_src: "images/dunley-farm/facilities.jpg",
        image_title: "Facilities"
      }
    ],
    desc: "Branding / Web Design / Web Development",
    client: "Dunley Farmhouse",
    finished_project: "New website and brand creation",
    date: "June 2015",
    brief:
      "<p>Dunley Farmhouse needed an online web presence to advertise their guest house to new customers. They had no existing branding but wanted to communicate to potential visitors the high spec of the farmhouse as well as the eco-friendly ethos of the farm.</p>",
    project:
      "<p>Since the need to be able to keep the website up to date with new reviews, photos and news from the farm was paramount, I created a customised Wordpress website. The site showcases the natural beauty of the farmhouse and surrounding farm land, whilst also highlighting the ethos behind the business.</p>",
    url: "http://www.dunleyfarm.co.uk/"
  },
  emails: {
    id: "9",
    name: "Emails",
    image: "images/emails-mockup.jpg",
    image_showcase: [
      {
        image_id: "41",
        image_src: "images/emails/amnesty.jpg",
        image_title: "Amnesty"
      },
      {
        image_id: "42",
        image_src: "images/emails/chiltern.jpg",
        image_title: "Chiltern Railways"
      },
      {
        image_id: "43",
        image_src: "images/emails/turtle-mat.jpg",
        image_title: "Turtle Mat"
      },
      {
        image_id: "44",
        image_src: "images/emails/st-christophers.jpg",
        image_title: "St Christopher's Inns"
      },
      {
        image_id: "45",
        image_src: "images/emails/harvey-john.jpg",
        image_title: "Harvey John"
      }
    ],
    desc: "Email Design / Email Development",
    client: "Various",
    finished_project: "Designing and building responsive HTML emails",
    date: "December 2018",
    brief:
      "<p>I created HTML emails for a range of high profile brands. Each brand has a different set of requirements, objectives and audience.</p>",
    project:
      "<p>Below are some examples of HTML emails I have designed and built, each to the client's specification. Every email is responsive on email devices and optimised for consistent rendering across email platforms.</p>",
    url: ""
  },
  bridalhaircompany: {
    id: "10",
    name: "Bridal Hair Company",
    image: "images/bridal-hair-mockup.jpg",
    image_showcase: [
      {
        image_id: "46",
        image_src: "images/bridal-hair/home.jpg",
        image_title: "Home"
      },
      {
        image_id: "47",
        image_src: "images/bridal-hair/services.jpg",
        image_title: "Services Overview"
      },
      {
        image_id: "48",
        image_src: "images/bridal-hair/gallery.jpg",
        image_title: "Gallery"
      }
    ],
    desc: "Branding / Web design / Web Development",
    client: "Bridal Hair Company",
    finished_project: "New logo, design and build Worpdress website",
    date: "June 2016",
    brief:
      "<p>Bridal Hair Company is a wedding make-up and hair service based in Brighton. They needed a new website that would refresh the branding and look clean and modern for brides-to-be.</p><p>The website needed to showcase the portfolio of work and enable Bridal Hair Company to easily keep the content updated with new information and photos.</p>",
    project:
      "<p>I created a fresh and modern website with large images to highlight recent work. I also moved the website over to Wordpress to ensure that it would be easy to keep it updated in the future.</p><p>To ensure Bridal Hair Company had the tools required to add new images, create new content and add new portfolio items as they occured, I ran several training sessions with them during the course of the project. I also wrote a written guide for them to keep for their reference.</p>",
    url: "http://www.bridalhaircompany.co.uk/"
  }
};
  
  export default projects;
  