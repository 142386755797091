import React from 'react';
import { Link } from "@reach/router";

import BrandLogo from '../images/logo.png';
import Navigation from './Navigation';

import {
    StyledHeader,
    StyledLogo
} from '../styles/StyledHeader';
import { Container, Col, Row } from 'react-grid-system';

const Header = () => (
  <StyledHeader>
    <Container>
      <Row>
        <Col md={2}>
          <Link to="/">
          <StyledLogo src={BrandLogo} alt="32Pixels Logo"/>
          </Link>
        </Col>
        <Col md={8}>
          <Navigation type="header-navigation"/>
        </Col>
      </Row>
    </Container>
  </StyledHeader>
);

export default Header;
