import React from "react";
import ReactHtmlParser from "react-html-parser";

//import components
import Footer from "./elements/Footer";
import DefaultBtn from "./elements/DefaultBtn";
import Spinner from "./elements/Spinner";
import ModalImage from "react-modal-image";
import { Container, Row, Col } from "react-grid-system";
import {
  StyledSingleProject,
  StyledSingleProjectInfoBox
} from "./styles/StyledSingleProject";

import {StyledProjectTile} from "./styles/StyledProjectTile";

import { useProjectFetch } from "./hooks/useProjectFetch";
const Projects = ({ id }) => {
  const [project, error, loading] = useProjectFetch(id);
    if (error) return <div>Something went wrong ...</div>;

    if (loading) return <Spinner />;

  return (
    <>
      <StyledSingleProject>
        <Container>
          <Row>
            <Col>
              <h2>{project.name}</h2>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="align-top">
              <img src={`../${project.image}`} alt={project.name} />
              <StyledSingleProjectInfoBox>
                <h3>Project Summary</h3>
                <div>Client: {project.client}</div>
                <div>Finished Project: {project.finished_project}</div>
                <div>Date: {project.date}</div>
              </StyledSingleProjectInfoBox>
            </Col>
            <Col md={8}>
              <h3>The Brief</h3>
              <div>{ReactHtmlParser(project.brief)}</div>
              <h3>The Project</h3>
              <div>{ReactHtmlParser(project.project)}</div>
            </Col>
          </Row>
          {project.testimonial !== "" && (
            <Row className="margin-top-20">
             <Col md={12}>
               <h3>Nice Words</h3>
               <div>{ReactHtmlParser(project.testimonial)}</div>
             </Col>
           </Row>
          )}
          {project.image_showcase.length > 0 && (
            <Row className="margin-top-40">
              {project.image_showcase.map(element => (
                <Col md={4} key={element.image_id}>
                  <StyledProjectTile>
                    <ModalImage
                      small={`../${element.image_src}`}
                      medium={`../${element.image_src}`}
                      alt={element.image_title}
                      hideDownload="true"
                      hideZoom="true"
                      onClose="true"
                    />
                  </StyledProjectTile>
                  <div className="showcase-title">{element.image_title}</div>
                </Col>
              ))}
            </Row>
          )}
          {project.url !== "" && (
            <Row className="margin-top-40">
              <Col md={12}>
                <a href={project.url} target="_blank"  rel="noopener noreferrer">
                  <DefaultBtn text="Visit website" align="center" />
                </a>
              </Col>
            </Row>
          )}
        </Container>
      </StyledSingleProject>
      <Footer />
    </>
  );
};

export default Projects;
