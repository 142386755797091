import React from 'react';

import Heading from './Heading';
import { Container, Row, Col } from 'react-grid-system';
import { StyledAbout } from '../styles/StyledAbout';
import Annie from '../images/annie.jpeg';

const About = ({header}) => (
 <StyledAbout>
    <Container>
      <Row>
        <Col>
          <Heading text={header} />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <p>Hi, my name is Annie and I am a web developer and graphic designer. I'm based between Haywards Heath, West Sussex, and Brighton. With a background in SEO, graphic design and marketing and a passion for creative problem solving, I can help you achieve your business goals.</p>
          <p>No project is too small; from bespoke websites to infographics and web banners, I can create a range of digital solutions to meet your business needs.</p>
          <p>Recent print work has been selected to be featured on <a rel="noopener noreferrer"  href="https://www.designrush.com/best-designs/print" target='_blank' className='inline-link'>Design Rush - Best Print Designs of 2023</a></p>
        </Col>
        <Col md={4}>
          <img src={Annie} alt="Annie, 32Pixels founder" />
          <a href="https://dribbble.com/AnnieF-K" target="_blank" rel="noopener noreferrer">
            Dribbble
        </a>
        </Col>
      </Row>
    </Container>
 </StyledAbout>
);

export default About;
