import React from 'react';
import {Container, Row, Col} from 'react-grid-system';
import Heading from './Heading';

const ProjectGrid = ({header, children}) => (
  <Container>
    <Row>
     <Col>
        <Heading text={header}/>
     </Col>
     </Row>
     <Row>
      { children }
     </Row>
  </Container>
);

export default ProjectGrid;
