import React from 'react';

//import components
import ProjectGrid from './elements/ProjectGrid';
import ProjectTile from './elements/ProjectTile';
import Footer from './elements/Footer';
import Spinner from './elements/Spinner';

// Custom Hook
import { useHomeFetch } from './hooks/useHomeFetch';

const Projects = () => {
  const [
    {
      state : {projects},
      error
    },
  ] = useHomeFetch();

  if (error) return <div>Something went wrong ...</div>;

  if(!projects[0]) return <Spinner />

  return (
    <>
      <ProjectGrid header="Projects">
        {Object.keys(projects[0]).map(project => (
          <ProjectTile
            key={projects[0][project].id}
            id={project}
            image={projects[0][project].image}
            projectName={projects[0][project].name}
            desc={projects[0][project].desc}
            sender="projects"
          />
        ))}
      </ProjectGrid>
      <Footer />
    </>
  );
};

export default Projects;
