import React from 'react';
import { Link } from "@reach/router";
//import components
import HomeHeader from './elements/HomeHeader';
import ProjectGrid from './elements/ProjectGrid';
import ProjectTile from './elements/ProjectTile';
import About from './elements/About';
import Testimonials from './elements/Testimonials';
import Spinner from './elements/Testimonials';
import Footer from './elements/Footer';
import DefaultBtn from './elements/DefaultBtn';
import ContactMe from "./elements/ContactMe";

import { Row, Col, Container } from 'react-grid-system';

// Custom Hook
import { useHomeFetch } from './hooks/useHomeFetch';

const Home = () => {
  const [
    {
      state : {projects},
      error
    }
  ] = useHomeFetch();


  if(error) return <div>Something went wrong ...</div>
  if(!projects[0]) return <Spinner />

  return (
    <>
      <HomeHeader />
      <ProjectGrid header="Recent Work">
        {Object.keys(projects[0])
          .slice(0, 3)
          .map(project => {
            return (
              <ProjectTile
                key={projects[0][project].id}
                id={project}
                image={projects[0][project].image}
                projectName={projects[0][project].name}
                desc={projects[0][project].desc}
                sender="home"
              />
            );
          })}
      </ProjectGrid>
      <Container>
        <Row>
          <Col>
            <Link to="/projects">
              <DefaultBtn text="View More" align="center" />
            </Link>
          </Col>
        </Row>
      </Container>
      <About header="About" />
      <Testimonials header="Nice words" />
      <ContactMe />
      <Footer />
    </>
  );
};

export default Home;
