import React from 'react';
import { Router } from '@reach/router';

import Header from './elements/Header';
import Home from './Home';
import Projects from './Projects';
import Project from './Project';

import {ThemeProvider} from 'styled-components';

import {GlobalStyle} from './styles/GlobalStyle';
import { theme } from './styles/GlobalStyle';

const App = () => (
  <ThemeProvider theme={theme}>
    <Header />
    <Router>
      <Home path="/" />
      <Projects path="/projects" />
      <Project path="/project/:id" />
    </Router>

    <GlobalStyle/>
  </ ThemeProvider>
)

export default App;