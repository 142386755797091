import styled from 'styled-components';

export const StyledHeading = styled.h2 `
    line-height: 3rem;;
    position: relative;
    z-index: 2;
    margin-bottom:30px;
    
    &:before {
        content: '${props => props.before}';
        color: ${props => props.theme.yellow};;
        opacity: 0.3;
        position: absolute;
        bottom: 2rem;
        left: 100px;
        font-size: 6rem;
        z-index: -100;
        font-weight: 300;

        @media screen and (max-width: 500px){
            font-size: 3rem;
        }
    }
`