import React from 'react';

import { StyledDefaultBtn } from '../styles/StyledDefaultBtn';

const DefaultBtn = ({text, align}) => (
  <StyledDefaultBtn type="submit" align={align}>
    {text}
  </StyledDefaultBtn>
);

export default DefaultBtn;
