import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import Navigation from './Navigation';

const Footer = () => (
  <Container>
  	<Row>
  		<Col className="border-top-grey">
    		<p className="center sm-txt">&copy;{(new Date().getFullYear())} 32Pixels</p>
    		<Navigation type="footer-navigation"/>
    	</Col>
  	</Row>
  </Container>
);

export default Footer;
