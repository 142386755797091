import { createGlobalStyle } from "styled-components";

export const theme = {
  yellow: "#ebe94a",
  yellowDarken: "#cfcd43",
  black: "#3f3f3f",
  lightGrey: "#f1f3f4"
};

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  body, input, button {
    font-size: 10px;
    font-family:'Yanone Kaffeesatz', Arial, sans-serif;
    color: #3f3f3f;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }

  //global styles

  //alignment
  .align-right {
    text-align:right;
  }
  .align-bottom {
    align-self: flex-end;
  }
  .align-top {
    align-self: flex-start;
  }

  //Spacing
  .margin-top-40 {
    margin-top:40px;
  }

  .center {
    text-align:center;
  }

  //text
  h1, h2, h3, h4, h5 {
    text-transform: uppercase;
  }
  h2 {
    font-size:3rem;
  }
  h3 {
    font-size:2rem;
  }
  p {
    margin-bottom: 20px;
    line-height: 1.4rem;
    font-size: 1.2rem;
  }
  .sm-txt {
    font-size: 1rem;
  }
  a {
    color: ${props => props.theme.black};
    font-size: 1.2rem;
  }
  .border-top-grey {
    border-top: 1px solid ${props => props.theme.lightGrey};
    margin-top: 40px;
    padding-top: 20px;
  }
`;
