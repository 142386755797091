import React from 'react';
import { Link } from '@reach/router';
import { StyledProjectTile, StyledProjectTitleContainer } from '../styles/StyledProjectTile';
import { Col } from 'react-grid-system';


const ProjectTile = ({ image, projectName, desc, id, sender }) => {
  return (
    <Col md={4}>
      <Link to={sender === "home" ? `project/${id}` : `../project/${id}`}>
        <StyledProjectTile>
          <img src={image} alt={projectName} />
          <StyledProjectTitleContainer>
            <h4>{desc}</h4>
            <h3>
              {projectName} <span>&gt;</span>
            </h3>
          </StyledProjectTitleContainer>
        </StyledProjectTile>
      </Link>
    </Col>
  );
};

export default ProjectTile;
