import { useState, useEffect } from 'react';

import projects from "../../projects.js";

export const useHomeFetch = () => {
  const [state, setState] = useState({ projects: [] });
  const [error, setError] = useState(false);

  const fetchProjects = async endpoint => {
    setError(false);

    try {
      setState(prev => ({
        ...prev,
        projects:
          [...prev.projects, projects]
      }))

    } catch (error) {
      setError(true);
      console.log(error);
    }
  }

  useEffect(() => {
    fetchProjects(projects);
  }, [])


  return [{ state, error}];
}
