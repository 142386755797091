import { useState, useEffect, useCallback } from "react";
import project from "../../projects.js";

export const useProjectFetch = id => {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchData = useCallback(async () => {
    setError(false);
    setLoading(true);

    try {

    const result = await (await project[id]);

      setState({
        ...result,
      });
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    // if (localStorage[id]) {
    //   setState(JSON.parse(localStorage[id]));
//          setLoading(false);
    // } else {
      fetchData();
    // }
  }, [fetchData, id]);

  useEffect(() => {
    localStorage.setItem(id, JSON.stringify(state));
  }, [id, state]);

  return [state, error, loading];
};
