import React from "react";

import { Container, Row, Col } from "react-grid-system";
import Heading from './Heading';

const ContactMe = () => (
  <Container id="contact">
    <Row>
      <Col className="margin-top-40">
        <Heading text="Get in touch" />
      </Col>
    </Row>
    <Row>
      <Col className="center">
        <p>Want to know more? Send me a message at:</p>
        <a href="mailto:annie@32pixels.co.uk">annie@32pixels.co.uk</a>
      </Col>
    </Row>
  </Container>
);

export default ContactMe;
