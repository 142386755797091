import styled from 'styled-components';

export const StyledDefaultBtn = styled.button`
  background: ${props => props.theme.yellow};
  padding: 20px 30px;
  width: auto;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  cursor: pointer;
  display: block;
  margin: ${props => (props.align ? '0 auto' : '')};
  font-size:1.2rem;

  &:hover {
    background: ${props => props.theme.yellowDarken};
  }

  @media screen and (max-width: 1000px){
    padding: 10px 15px;
  }
`;
