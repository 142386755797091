import React from 'react';

import DefaultBtn from './DefaultBtn';
import { StyledHomeHeader } from '../styles/StyledHomeHeader';

import { Container, Row, Col } from 'react-grid-system';

const HomeHeader = () => (
  <StyledHomeHeader>
    <Container>
      <Row>
        <Col md={3} className="align-bottom">
          <a href="/#contact">
            <DefaultBtn text="Get in touch" />
          </a>
        </Col>
        <Col md={9} className="align-right">
          <h1>I am...</h1>
          <h3>a Front-end Developer and Graphic Designer</h3>
        </Col>
      </Row>
    </Container>
  </StyledHomeHeader>
);

export default HomeHeader;
