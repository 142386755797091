import styled from 'styled-components';

export const StyledTestimonials = styled.div `
    background: ${props => props.theme.lightGrey};
    padding: 80px 0 40px;
    margin: 40px 0;

    .testimonial-text {
    	text-align:center;
    	max-width: 750px;
    	margin: 0 auto;
    }
    .testimonial-client {
    	text-align: center;
    	margin: 20px;
    }
`;

export const StyledTestimonialsThumb = styled.img `
	display:block;
	margin: 40px auto;
	width:100%;
	max-width: 175px;
`;