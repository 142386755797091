import styled from 'styled-components';

export const StyledProjectTitleContainer = styled.div`
         position: absolute;
         bottom: 0;
         left: 0;
         right: 0;
         opacity: 0;
         transition: 0.3s ease-out;
         transition-property: opacity, transform, filter;
         transform: translateY(100%);
         background: rgba(255, 255, 255, 0.9);
         padding: 30px;

         h4 {
           font-weight: normal;
           font-size: 0.8rem;
         }
         h3 {
           font-weight: normal;
           margin-top: 10px;
           font-size: 1.2rem;
         }

         span {
           color: ${props => props.theme.yellow};
           margin-left: 20px;
         }
         a {
           color: ${props => props.theme.black};
         }
       `;

export const StyledProjectTile = styled.div`
         width: 100%;
         height: auto;
         box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
         background: white;
         position: relative;
         overflow: hidden;
         margin-bottom: 40px;

         img {
           max-width: 100%;
           display: block;
         }
         &:hover ${StyledProjectTitleContainer} {
           opacity: 1;
           transform: translateY(0);
         }
       `;

