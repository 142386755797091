import styled from "styled-components";

export const StyledHeader = styled.div`
  background: ${props => props.theme.lightGrey};
  box-sizing: border-box;
  z-index: 1;
  position: relative;
  margin-bottom: 140px;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: -50px;
    left: 0px;
    z-index: -1;
    transform: skew(0deg, 2deg);
    background-color: ${props => props.theme.lightGrey};
  }

  .header-content {
    max-width: 1280px;
    min-height: 120px;
    padding: 20px 0px;
    margin: 0 auto;
    box-sizing: border-box;

    @media screen and (max-width: 500px) {
      max-width: 1280px;
      min-height: 0px;
    }
  }
`;

export const StyledLogo = styled.img`
  width: 100px;
  margin-top: 20px;

  @media screen and (max-width: 500px) {
    width: 50px;
  }
`;
