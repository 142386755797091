import styled from 'styled-components';

export const StyledAbout = styled.div `
    margin-top:40px;

    img {
        display:block;
        margin: 0 auto;
        max-width: 100%;
    }
    a {
        display:block;
        text-decoration: underline;
        margin: 20px auto 0;
        text-align:center;
        font-size: 1.2rem;
        color: ${props => props.theme.black};
    }
    .inline-link {
        display: inline-block;
        text-decoration: underline;
    }
`