import React from 'react';
import { Link } from '@reach/router';

import { StyledNavigation } from '../styles/StyledNavigation';

const Navigation = ({type}) => (
  <StyledNavigation>
    <div className={type}>
      <Link to="/">
        <p>Home</p>
      </Link>
      <Link to="/projects">
        <p>Projects</p>
      </Link>
      <a href="/#contact">
        <p>Contact</p>
      </a>
    </div>
  </StyledNavigation>
);

export default Navigation;
