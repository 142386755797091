import styled from 'styled-components';

export const StyledHomeHeader = styled.div`
  background: ${props => props.theme.lightGrey};
  padding: 0px 20px;
  box-sizing: border-box;
  margin: -130px 0 10%;
  position:relative;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: -50px;
    left: 0px;
    z-index: -1;
    transform: skew(0deg, 2deg);
    background-color: ${props => props.theme.lightGrey};
  }

  @media screen and (max-width: 760px){
    margin: -130px 0 30%;
  }

  h1 {
    font-size: 4rem;
    color: ${props => props.theme.black};
    opacity: 0.1;
    font-weight: 400;
  }
  h3 {
    color: ${props => props.theme.yellow};
    font-size: 3rem;
    font-weight: 400;
  }
`;
