import styled from 'styled-components';

export const StyledNavigation = styled.div`
  text-align:center;
  text-transform: uppercase;

  p {
    display: inline-block;
    color: ${props => props.theme.black};
    cursor: pointer;
    transform: all ease-in-out 0.2s;
    position: relative;
  }

  .header-navigation {
    padding-top:20px;

    p {
      font-size: 1.2rem;
      padding: 20px 15px 0px;
      
      &:hover {
        opacity: 0.8;
      }

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        top: 0;
        left: 0;
        background-color: ${props => props.theme.yellow};
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
      }

      &:hover {
        opacity: 1;

        &:before {
          visibility: visible;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
    }
    @media screen and (max-width: 500px){
      display: none;
    }
  }

  .footer-navigation {
    p {
      padding: 20px 15px 0px;
      font-size: 1rem;

      &:hover {
        color: ${props => props.theme.yellow};
      }
    }
  }
`;
